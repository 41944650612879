export function isTalentLabs() {
  const runTimeConfig = useRuntimeConfig()
  return (
    runTimeConfig.public.lineOfBusiness === 'TALENTLABS' &&
    runTimeConfig.public.regionOfBusiness === 'GLOBAL'
  )
}

export function isJobBoard() {
  const runTimeConfig = useRuntimeConfig()
  return runTimeConfig.public.lineOfBusiness === 'JOB_BOARD'
}
